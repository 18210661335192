






































































































































































































import { Vue, Component } from "vue-property-decorator";
import DoctorAppointmentService from "../../service/DoctorAppointmentService";
import { IDoctorAppointment } from "../../models/DoctorAppointment";
import AlertService from "../../../common-app/service/AlertService";

@Component
export default class List extends Vue {
  public appointApprovedList: IDoctorAppointment[] = [];
  public appointApprovedListPaginate: IDoctorAppointment[] = [];
  public appointApprovedListPaginatePending: IDoctorAppointment[] = [];

  public appointPendingList: IDoctorAppointment[] = [];
  public appointment: IDoctorAppointment = {} as IDoctorAppointment;
  public currentDate: Date = new Date();
  public fromDate: Date = new Date();
  public toDate: Date = new Date();
  public currentPage = 1;
  public perPage = 10;
  public rows = 10;

  public perPagePending = 10;
  public rowsPending = 10;

  created() {
    // DoctorAppointmentService.GetAppoitmentByDrId().then((res) => {
    //   let appointList: IDoctorAppointment[] = res.data.result || [];
    //   //console.log(appointList);
    //   this.appointApprovedList = appointList.filter((s) => s.isApproved==1);
    //   this.appointPendingList = appointList.filter((s) => s.isApproved==0 || s.isApproved==3);
    // //  console.log(this.appointPendingList);
    // });
    // this.GetAppointmentList();

    var someDate = new Date();
    var numberOfDaysToAdd = 6;
    var result = someDate.setDate(someDate.getDate() + numberOfDaysToAdd);
    this.toDate = new Date(result);
    //console.log(new Date(result));

    // DoctorAppointmentService.GetAppoitmentByDate(this.fromDate, this.toDate).then((res) => {
    //   let appointList: IDoctorAppointment[] = res.data.result || [];
    //   this.appointApprovedList = appointList.filter((s) => s.isApproved == 1);
    //   this.appointPendingList = appointList.filter((s) => s.isApproved == 0 || s.isApproved == 3);
    // });
    this.getAppointmentListDateRange();
  }

  GetAppointmentList() {
    DoctorAppointmentService.GetAppoitmentByDrId().then((res) => {
      let appointList: IDoctorAppointment[] = res.data.result || [];
      this.appointApprovedList = appointList.filter((s) => s.isApproved == 1);
      this.appointPendingList = appointList.filter((s) => s.isApproved == 0 || s.isApproved == 3);
    });
  }

  getAppointmentListDateRange(){
    DoctorAppointmentService.GetAppoitmentByDate(this.fromDate, this.toDate).then((res) => {
      let appointList: IDoctorAppointment[] = res.data.result || [];
      this.appointApprovedList = appointList.filter((s) => s.isApproved == 1);
      //console.log(this.appointApprovedList);
      this.rows=this.appointApprovedList.length;
      this.paginate(this.perPage, 0);
      
      this.appointPendingList = appointList.filter((s) => s.isApproved == 0 || s.isApproved == 3);
      this.rowsPending=this.appointPendingList.length;
      this.paginatePending(this.perPagePending, 0);
      //console.log(this.appointPendingList);
    });
  }

  onPageChanged(page: number) {
    this.paginate(this.perPage, page - 1);
  }

  onPageChangedPending(page: number) {
    this.paginatePending(this.perPagePending, page - 1);
  }

  paginate(page_size: number, page_number: number) {
    this.appointApprovedListPaginate = [];
    this.appointApprovedListPaginate = this.appointApprovedList.slice(page_number * page_size, (page_number + 1) * page_size);
  }

  paginatePending(page_size: number, page_number: number) {
    this.appointApprovedListPaginatePending = [];
    this.appointApprovedListPaginatePending = this.appointPendingList.slice(page_number * page_size, (page_number + 1) * page_size);
  }

  status(apId: number, isActive: number) {
    this.$bvModal
      .msgBoxConfirm("Do you really want to approve?", {
        title: "Please Confirm",
        okVariant: "danger",
        okTitle: "NO",
        cancelVariant: "success",
        cancelTitle: "YES",
        hideHeaderClose: true,
        headerClass: "justify-content-center",
        bodyClass: "text-center",
        centered: true,
        footerClass: "justify-content-center border-top-0 flex-wrap-reverse",
      })
      .then((value) => {
        if (value == false) {
          this.appointment.id = apId;
          this.appointment.isApproved = isActive;
          DoctorAppointmentService.approvedUnApproved(this.appointment).then((res) => {
            if (isActive == 1) {
              this.appointApprovedList.push(this.appointPendingList.find((s) => s.id == apId) || ({} as IDoctorAppointment));
              this.appointPendingList = this.appointPendingList.filter((s) => s.id !== apId);
              AlertService.sendMessage({ status: true, message: "Appointment Approved" });
              this.getAppointmentListDateRange();
            } else {
              this.appointPendingList.push(this.appointApprovedList.find((s) => s.id == apId) || ({} as IDoctorAppointment));
              this.appointApprovedList = this.appointPendingList.filter((s) => s.id !== apId);
              AlertService.sendMessage({ status: true, message: "Appointment Canceled" });
              this.getAppointmentListDateRange();
            }
          });
        }
        // else
        // {
        //   // DoctorAppointmentService.approvedUnApproved(apId, isActive).then((res) => {
        //    this.appointment.id=apId;
        //   this.appointment.isApproved=isActive;
        //   DoctorAppointmentService.approvedUnApproved(this.appointment).then((res) => {
        //     if (isActive) {
        //       this.appointApprovedList.push(this.appointPendingList.find((s) => s.id == apId) || ({} as IDoctorAppointment));
        //       this.appointPendingList = this.appointPendingList.filter((s) => s.id !== apId);
        //       AlertService.sendMessage({ status: true, message: "Appointment Approved" });
        //     } else {
        //       this.appointPendingList.push(this.appointApprovedList.find((s) => s.id == apId) || ({} as IDoctorAppointment));
        //       this.appointApprovedList = this.appointPendingList.filter((s) => s.id !== apId);
        //       AlertService.sendMessage({ status: true, message: "Appointment Canceled" });
        //     }
        //   });
        // }
      });
  }

  searchAppointment() {
    DoctorAppointmentService.GetAppoitmentByDate(this.fromDate, this.toDate).then((res) => {
      let appointList: IDoctorAppointment[] = res.data.result || [];
      this.appointApprovedList = appointList.filter((s) => s.isApproved == 1);
      this.rows=this.appointApprovedList.length;
      this.paginate(this.perPage, 0);
      this.appointPendingList = appointList.filter((s) => s.isApproved == 0 || s.isApproved == 3);
      this.rowsPending=this.appointPendingList.length;
      this.paginatePending(this.perPagePending, 0);
    });
  }
  cancel(id: number) {
    this.$bvModal
      .msgBoxConfirm("Do you really want to cancel?", {
        title: "Please Confirm",
        okVariant: "success",
        okTitle: "NO",
        cancelVariant: "danger",
        cancelTitle: "YES",
        hideHeaderClose: true,
        headerClass: "justify-content-center",
        bodyClass: "text-center",
        centered: true,
        footerClass: "justify-content-center border-top-0 flex-wrap-reverse",
      })
      .then((value) => {
        if (value == false) {
          DoctorAppointmentService.cancel(id).then((res) => {
            if (res.data.status) {
              this.appointPendingList = this.appointPendingList.filter((s) => s.id !== id);
              AlertService.sendMessage({ status: true, message: "Appointment Canceled" });
              this.getAppointmentListDateRange();
            }
          });
        }
      });
  }

  isSameDay(apptDate: Date) {
    apptDate = new Date(apptDate);
    if (this.currentDate <= apptDate) {
      return true;
    }
    // if(this.currentDate.getFullYear() === date2.getFullYear() &&
    //   this.currentDate.getMonth() === date2.getMonth() &&
    //   this.currentDate.getDay() === date2.getDay())
    //   {
    //      return true
    //   }
    return false;
  }
}
